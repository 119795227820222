/**   
 * api接口统一管理
 */
import { get, post, put, del } from './http'
// 注册
export const apiRegister = p => post('users/register', p);

// 登录
export const apiLogin = p => post('users/login', p);

// 登出
export const apiLogOut = p => get('users/logout', p);

// 用户明细
export const apiUsersDetail = p => get('/users/' + p);

// 用户忘记密码
export const apiUsersForget = p => post('users/forget', p);

// 用戶重置密碼
export const apiUsersResetPassword = p => post('users/resetPassword', p);

// 用户修改
export const apiUsers = (p, data) => put('/users/' + p, data);

// 用戶申請兌換VIP
export const userVipApplications = p => post('/userVipApplications/',p);


// 圖片上傳
export const apiImageUpload = p => post('/files/uploadImage', p);


// Banner列表
export const apiBanner = p => get('/sysBanners', p);


// 分享類型列表
export const apiShareTypes = p => get('/shareTypes', p);

// 分享列表
export const apiShares = p => get('/shares', p);

// 分享明细
export const apiSharesDetail = p => get('/shares/' + p);


// 打卡任務列表
export const apiClocks = p => get('/clocks/list', p);

// 添加自定義打卡任務
export const apiAddClocks = p => post('/clocks', p);

// 刪除任務列表
export const apiDeleteClocks = (p,data) => del('/clocks/' + p,data);

// 打卡任務明細
export const apiDetailClocks = (p,data) => get('/clocks/' + p,data);

// 修改任务
export const apiUpdateClocks = (p, data) => put('/clocks/' + p, data);

// 用戶創建打卡記錄 - 簡單勾選
export const apiUserCheckClockLogs = p => post('/userClockLogs', p);

// 用戶創建打卡記錄 - 今日報數
export const apiCreateUserClockLogs = p => post('/userClockLogs', p);

// 用戶創建打卡記錄 - 閱讀筆記
export const apiCreateUserClockLogsNote = p => post('/userClockLogs', p);

// 用戶打卡記錄列表
export const apiUserClockLogsList = p => get('/userClockLogs', p);

// 用戶打卡記錄明細
export const apiUserClockLogsDetail = (p,data) => put('/userClockLogs/'+p, data);

//修改用戶打卡記錄
export const apiUpdateUserClockLogs = (p, data) => put('/userClockLogs/' + p, data);

// 用戶創建打卡記錄 - 名單及位置記
export const apiCreateUserClockLogsLocation = p => post('/userClockLogs', p);

// 課程類型列表
export const apiCreateCourseTypes = p => get('/courseTypes', p);

// 課程類型明細
export const apiCourseTypesDetail = (p, data) => get('/courseTypes/' + p, data);


// 搜索課程課堂
export const apiSearch = p => get('/search' , p);

// 首頁 - 課程類型列表
export const apiHome = get('/courseTypes/home');

// 課堂列表
export const apiCourseList = p => get('/courses', p);
// 課堂明細
export const apiCoursesDetail = (p, data) => get('/courses/' + p, data);


// 用戶收藏課程
export const apiUserCourseFavorites = p => post('/userCourseFavorites', p);
// 用戶取消收藏課程
export const apiUserDeleteCourseFavorites = p => del('/userCourseFavorites/'+p);
// 書簽 - 用戶收藏課堂列表
export const apiUserCourseFavoritesList = p => get('/userCourseFavorites/',p);


// 完成 - 用戶添加完成課堂
export const apiUserCourseCompletions = data => post('/userCourseCompletions',data);

// 完成 - 修改用戶完成課堂(也就是評論)
export const apiUserUpdateCourseCompletions = (p,data) => put('/userCourseCompletions/'+p,data);

// 完成 - 查看用戶完成課堂
export const apiUserGetCourseCompletions = p => get('/userCourseCompletions/'+p);






