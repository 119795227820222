<template>
    <div>
      <Header/>
      <div class="today_content_text">
        <!-- <router-link :to="{path:'../../'}">
          <img class="today_content_img_1" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="today_content_img_2" src="../../assets/task/time_icon.png">
        <div>{{task}}</div>
        <div class="today_rank_div">
          <!-- <router-link :to="{path:'/my/activation_record'}"> -->
          <!-- <img class="today_rank_icons" src="../../assets/task/count_off_today/rank_icon.png" @click="activation_record"> -->
          <!-- </router-link> -->

          <img class="today_rank_icons" src="../../assets/task/count_off_today/rank_icon.png" >
        </div>
      </div>
        <div style="padding:20px">
            <p class="today_p_one">{{count_off_today}}</p>
            <p style="font-size: 14px;"></p>
            <div style="margin-top: 20px;">
                <button class="today_button_1 to_day_round-btn" @click="target">設置目標</button>
                <!-- 查看成績表目前還不確定功能先隱藏 -->
                <button class="today_button_2 to_day_round-btn" style="border:none;color: #c49b6c;">查看成績表</button>
            </div>
            <p class="to_day_p" v-if="targetNumber">{{target_number}}：{{targetNumber}}</p>
        </div>
        <div style="padding:17px;">
            <div class="today_grace-forms">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">日期</div>
                <div>
                    <!-- <input type="text" placeholder="請選擇時間" v-model="clock_date" class="today_input"> -->
                    <el-date-picker
                        v-model="clock_date"
                        type="date"
                        placeholder="請選擇時間">
                        </el-date-picker>
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{online_conversations}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="online_conversation" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{call_phones}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="call_phone" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{interview_contents}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value"  v-model="interview_content" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{demo_concept}}/{{product}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value"  v-model="demo_concept_product" class="today_input">
                </div>
                </div>
            </div>

            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{try_clinchs}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="try_clinch" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{open_cases}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="open_case" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{get_referrals}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="get_referral" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{sign_bills}}</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="sign_bill" class="today_input">
                </div>
                </div>
            </div>
            <div class="today_grace-forms today_grace">
                <div class="today_grace-form-items">
                <div class="today_grace-form-label">{{commissions}}$</div>
                <div class="today_grace-form-body">
                    <input type="text" :placeholder="value" v-model="commission" class="today_input">
                </div>
                </div>
            </div>

            <div style="margin: 30px;">
                <img class="to_day_img" @click="accomplish" src="../../assets/task/count_off_today/done_btn.png">
            </div>
        </div>
         <div class="to_day_grace-btdialog-shade_my" v-if="redeemCode_dialog" @click.stop="redeemCode">
                <div class="to_day_dialog_my to_day_gdIn_my" @click.stop="redeemCode">
                    <p style="text-align:center;color: rgb(102, 102, 102);margin-top: 3vh;font-weight: bold;">{{set_target_number}}</p>
                    <div style="padding: 0px 20px 20px 20px;">
                        <!-- <div class="to_day_grace-buttons_mys"> -->
                            <input class="to_day_grace-buttons_mys" v-model="targetNumber" type="number" :placeholder="input_target_number">
                        <!-- </div> -->
                        <button class="to_day_grace-buttons_my" @click="save">保存</button>
                    </div>
                </div>
            </div>
    </div>
</template>

<style>
.to_day_p{
    width:50%;
    height:auto;
    margin-top:1vh;
    font-size: 13px;
    color:#fff;
}
.to_day_grace-buttons_mys::-webkit-input-placeholder {
    text-align: center;
}
.to_day_grace-buttons_my{
    background: #C49B6C !important;
    width:100%;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    margin-top:3vh;
}
.to_day_grace-buttons_mys{
    /* background: #C49B6C !important; */
    width:100%;
    /* border: none; */
    color: #000;
    font-size: 20px;
    line-height: 5vh;
    padding: 0;
    /* border-radius: 2px; */
    margin-top:3vh;
    border-radius:10px;
    border: 1px solid #000;
    text-align: center;
}
.to_day_gdIn_my{
    animation: gdIn-data-v-2f613eb3 .2s linear forwards;
}
.to_day_dialog_my{
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.to_day_grace-btdialog-shade_my{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    background: rgba(0,0,0,.5);
    color: #fff;
}
.to_day_img{
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 34px;
}
::-webkit-input-placeholder{
    color:#ccc;
}
.today_grace{
    margin-top:2vh;
}
.today_input{
  width: 100%;
  padding: 3px 3px;
  font-size: 15px;
  border: none;
  background-color: rgb(196, 155, 108) !important;
  min-height: 4.4vh;
  color:#fff;
}
.today_grace-form-body{
  border: 1px solid hsla(0,0%,100%,.8);
  /* padding: 5px 8px; */
  color: #fff;
  width: 60%;
  overflow: hidden;
}
.today_grace-form-label{
    text-align: left;
    width: 40%;
    color: #fff!important;
    font-size: 14px;
    height: auto!important;
    line-height: 6vh;
  }
.today_grace-form-items{
    display: flex;
  }
.today_grace-forms{
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
p{
    margin:0;
}
.today_p_one{
    font-size:24px;
    text-align:left;
    color:#fff;
}
.to_day_round-btn{
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
}
.today_button_1,.today_button_2{
    height: 34px;
    width:45%;
    padding: 7px 10px;
    margin-left: 5px;
    margin-right: 5px;
}
  .today_content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .today_content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 58px;
  }
  .today_content_img_2{
    width: 50px;
    height: 48px;
    margin-right: 8px;
  }
  .today_rank_div{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
.today_rank_icons{
  height: 40px;
  margin-right: 8px;
  width: 40px;
}
</style>
<script>
import Header from "../Header";
import {apiUsers,apiDetailClocks,apiCreateUserClockLogs,apiUpdateUserClockLogs} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        redeemCode_dialog:false,
        targetNumber:0,
        todayUserClockLog:'',
        // 日期
        DateTime:'',
        // 線上對話
        online_conversation:'',
        // 打出電話
        call_phone:'',
        // 約談內容
        interview_content:'',
        // 演示概念/產品
        demo_concept_product:'',
        // 嘗試成交
        try_clinch:'',
        // 開case
        open_case:'',

        // 獲得轉介
        get_referral:'',
        // 簽單
        sign_bill:'',
        // 佣金
        commission:0,
        clock_date:'',
        lang_id:'',
        user_id:'',
        count_off_today:'',
        target_number:'',
        online_conversations:'',
        value:'',
        interview_contents:'',
        demo_concept:'',
        product:'',
        try_clinchs:'',
        open_cases:'',
        get_referrals:'',
        sign_bills:'',
        commissions:'',
        set_target_number:'',
        input_target_number:'',
        task:'',
    }
  },
  created(){
      let id = this.$route.params.id;
      this.targetNumber = this.$store.state.month_goal;
      let lang_id = this.$store.state.lang_id;
      this.lang_id = lang_id;
      if(lang_id == 1){
        this.count_off_today = this.$zh_ft('count_off_today');
        this.target_number = this.$zh_ft('target_number');
        this.online_conversations = this.$zh_ft('online_conversation');
        this.value = this.$zh_ft('value');
        this.call_phones = this.$zh_ft('call_phones');
        this.interview_contents = this.$zh_ft('interview_contents');
        this.demo_concept = this.$zh_ft('demo_concept');
        this.product = this.$zh_ft('product');
        this.try_clinchs = this.$zh_ft('try_clinchs');
        this.open_cases = this.$zh_ft('open_cases');
        this.get_referrals = this.$zh_ft('get_referrals');
        this.sign_bills = this.$zh_ft('sign_bills');
        this.commissions = this.$zh_ft('commissions');
        this.set_target_number = this.$zh_ft('set_target_number');
        this.input_target_number = this.$zh_ft('input_target_number');
        this.task = this.$zh_ft('task');
      }else{
        this.count_off_today = this.$zh_jt('count_off_today');
        this.target_number = this.$zh_jt('target_number');
        this.online_conversations = this.$zh_jt('online_conversation');
        this.value = this.$zh_jt('value');
        this.call_phones = this.$zh_jt('call_phones');
        this.interview_contents = this.$zh_jt('interview_contents');
        this.demo_concept = this.$zh_jt('demo_concept');
        this.product = this.$zh_jt('product');
        this.try_clinchs = this.$zh_jt('try_clinchs');
        this.open_cases = this.$zh_jt('open_cases');
        this.get_referrals = this.$zh_jt('get_referrals');
        this.sign_bills = this.$zh_jt('sign_bills');
        this.commissions = this.$zh_jt('commissions');
        this.set_target_number = this.$zh_jt('set_target_number');
        this.input_target_number = this.$zh_jt('input_target_number');
        this.task = this.$zh_jt('task');
      }
      this.user_id = this.$store.state.user_id;
      if(this.targetNumber == 0){
          this.redeemCode_dialog = true;
      }
      if(id && id>0){
        apiDetailClocks(id,{
            lang_id:this.lang_id,
            user_id:this.user_id,
        }).then(res=>{
            if(res.code == 200){
                if(res.data.todayUserClockLog[0]){
                    this.todayUserClockLog = res.data.todayUserClockLog[0];
                    this.online_conversation = res.data.todayUserClockLog[0].online_conversation;
                    this.call_phone= res.data.todayUserClockLog[0].call_phone;
                    this.interview_content= res.data.todayUserClockLog[0].interview_content;
                    this.demo_concept_product= res.data.todayUserClockLog[0].demo_concept_product;
                    this.try_clinch= res.data.todayUserClockLog[0].try_clinch;
                    this.open_case = res.data.todayUserClockLog[0].open_case;
                    this.get_referral= res.data.todayUserClockLog[0].get_referral;
                    this.sign_bill= res.data.todayUserClockLog[0].sign_bill;
                    this.commission= res.data.todayUserClockLog[0].commission;
                    this.clock_date= res.data.todayUserClockLog[0].clock_date;
                }
            }else{
                this.$message.error(res.message);
            }
        }).catch(error=>{
            console.log(error);
        })
      }
  },
  methods:{
      checkTime(i) {
    if (i < 10) {
      i = "0" + i
    }
    return i;
  },
  p(s) {
      return s < 10 ? '0' + s : s
    },
    accomplish(){
        if(this.clock_date){
            let datas = (this.clock_date).toString();

            if(datas.length > 40){
                this.clock_date = this.clock_date.getFullYear() + '-' + this.p((this.clock_date.getMonth() + 1)) + '-' + this.p(this.clock_date.getDate());
            }
        }

        let id = this.$route.params.id;
        let todayUserClockLog = this.todayUserClockLog;
        let data = {
            lang_id:this.$store.state.lang_id,
            online_conversation:this.online_conversation,
            call_phone:this.call_phone,
            interview_content:this.interview_content,
            demo_concept_product:this.demo_concept_product,
            try_clinch:this.try_clinch,
            open_case:this.open_case,
            get_referral:this.get_referral,
            sign_bill:this.sign_bill,
            commission:this.commission,
            user_id:this.user_id,
        }
        if(this.clock_date){
            data['clock_date'] = this.clock_date;
        }
        if(todayUserClockLog){
            apiUpdateUserClockLogs(todayUserClockLog.id,data).then(res=>{
                if(res.code == 200){
                    this.$message.success('修改成功');
                    location.reload();
                }else{
                    this.$message.error(res.message);
                    return false;
                }
            }).catch(error=>{
                console.log(error);
            })
        }else{
            data['clock_id'] = id;
            apiCreateUserClockLogs(data).then(res=>{
                if(res.code != 200){
                    this.$message.error(res.message);
                    return false;
                }else{
                    this.$message.success('添加成功');
                    location.reload();
                }
            }).catch(error=>{
                console.log(error);
            })
        }
    },
    activation_record(){
      this.$router.push({
        path:'/my/activation_record',
        query:{
          id:this.$route.params.id,
        }
      });
    },
    redeemCode(val){
        // 点击子元素时 阻止触发父级方法
        let event1 = val.currentTarget;
        let event2 = val.target;
        // 当event1 == event2 则表示点击的是子级以外的所有父级内容 则关闭输入兑换码
        if(event1 == event2){   
            this.redeemCode_dialog = false;
            this.$store.state.switchover = false;
        }
    },
    target(){
        this.redeemCode_dialog = true;
    },
    save(){
        let target = this.targetNumber;
        let user_id = this.$store.state.user_id;
        if(target || target == 0){
            apiUsers(user_id,{
                month_goal:Math.floor(this.targetNumber)
            }).then(res=>{
                if(res.code == 200){
                    window.localStorage.setItem("month_goal", Math.floor(this.targetNumber));
                    if(this.lang_id == 1){
                        this.$message.success('目標設置成功');
                    }else{
                        this.$message.success('目标设置成功');
                    }
                }else{
                    this.$message.error(res.message);
                    return false;
                }
            }).catch(error=>{
                console.log(error);
            })
            this.redeemCode_dialog = false;
        }
    }
  }
}
</script>
<style>
</style>